<template>
    <div class="ibox-title">
        <h3 v-if="close" @click="collapse" class="green"><slot/></h3>
        <h3 v-else><slot/></h3>
        <div v-if="!hideTools" class="ibox-tools">
            <a v-b-tooltip @click="collapse" :title="close ? 'Открыть' : 'Закрыть'" class="collapse-link">
                <i class="fa fa-chevron-up"></i>
            </a>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    export default {
        name: "IboxTitle",
        props: ['hideTools'],
        data() {
            return {
                close: false
            }
        },
        methods: {
            collapse() {
                let el = $(this.$el).closest('div.ibox-title').find('.collapse-link');
                let ibox = el.closest('div.ibox');
                let button = el.find('i');
                let content = ibox.children('.ibox-content');
                content.slideToggle(200);
                button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                ibox.toggleClass('').toggleClass('border-bottom');
                setTimeout(function () {
                    ibox.resize();
                }, 100);
                this.close = button.hasClass('fa-chevron-down');
            }
        }
    }
</script>

<style scoped>
    h3.green {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
