<template>
    <validation-provider :name="name" :rules="rules" v-slot="validationContext">
        <b-form-group :label="label">
            <Summernote :rows="rows" @input="onInput" :value="value" :state="valid(validationContext)"/>
            <b-form-invalid-feedback v-bind:class="{invalid: validationContext.errors.length > 0}">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
    import Summernote from "../Summernote";
    export default {
        name: "ValidSummernote",
        components: {Summernote},
        props: ['name', 'label', 'rules', 'value', 'rows'],
        methods: {
            onInput(value) {
                this.$emit('input', value);
            }
        }
    }
</script>

<style scoped>
    .invalid {
        display: block!important;
    }
</style>
