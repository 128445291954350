<template>
    <textarea :disabled="disabled" :rows="rows" :value="value"></textarea>
</template>

<script>
    import $ from 'jquery'
    import {} from 'bootstrap'
    import {} from 'summernote'
    window.jQuery = $
    export default {
        name: "Summernote",
        props: ["value", 'rows', 'disabled'],
        data() {
            return {
                obj: ""
            };
        },
        mounted() {
            let vue = this;
            vue.obj = $(this.$el);
            let option = {
              height: this.rows ? this.rows : 150,
              tableClassName: function() {
                this.className = 'table table-bordered';
                this.style.cssText = '';
              }
            };
            option.callbacks = {
                onChange(contents) {
                    vue.$emit("input", contents);
                }
            };
            vue.obj.summernote(option);
            vue.obj.summernote(this.disabled ? 'disable' : 'enable');
            $('.dropdown-toggle').dropdown()
        }
    }
</script>

<style lang="scss">
  @import 'https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.css';
</style>
